<template>
<section class="goods-manager">
    <div class="search-wrapper">
        <span>搜索：</span>
        <el-input class="search-input" placeholder="商品名称" v-model="search"></el-input>
        <el-button class="search-btn" type="primary" @click="searchGoods">搜索</el-button>
        <el-button class="search-btn"  @click="resetHandler">重置</el-button>
    </div>
    <h2 class="goods-title">商品列表</h2>
    <div class="goods-header flex space-between">
        <ul class="tab-wrapper">
            <li 
            :class="['tab-item',tabIndex==i?'tab-item_act':'']" 
            v-for="(item,i) in tabList" 
            @click="changeTabHandler(i)"
            :key="i">{{item.name}}({{item.num}})</li>
        </ul>
        <div>
            <el-button @click="changeStatusHandler(1)">批量上架</el-button>
            <el-button @click="changeStatusHandler(2)">批量下架</el-button>
            <router-link class="add-good" to="addGood">
                <span class="el-icon el-icon-plus"></span>
                <span>添加商品</span>
            </router-link>
        </div>
    </div>

    <!-- table -->
    <el-table
        class="table-wrapper"
        :data="list"
        :header-cell-style="{color: '#000',fontWeigth: 'bold',background: '#FAFAFA',textAlign:'center'}"
        @selection-change="selectionChangeHandler"
        style="width:100%;">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            label="商品名称"
            width="350">
            <template slot-scope="scope">
                <div class="flex align-center">
                    <!-- <img :src="scope.row.mainImg" alt="" class="table-img"> -->
                    <el-image class="table-img" :src="scope.row.mainImg" fit="cover" :preview-src-list="[scope.row.mainImg]">
                        <!-- 自定义占位内容 -->
                        <div slot="placeholder" class="image-slot flex flex-column flex-center"><i class="el-icon-loading"></i></div>
                        <!-- 自定义加载失败内容 -->
                        <div slot="error" class="image-slot flex flex-column flex-center"><i class="el-icon-picture-outline"></i></div>
                    </el-image>
                    <p class="table-name">
                        <span>{{scope.row.productName}}</span>
                    </p>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="quantity[0].count"
            align="center"
            label="库存">
        </el-table-column>
        <el-table-column
            align="center"
            label="价格">
            <template slot-scope="scope">
                <span class="mt-goods-price" v-if="scope.row.minPrice !== scope.row.maxPrice">￥{{ scope.row.minPrice || "0.00" }}~{{ scope.row.maxPrice || "0.00" }}</span>
                <span class="mt-goods-price" v-else>￥{{ scope.row.minPrice || "0.00" }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="createTimeS"
            align="center"
            sortable
            label="创建时间" width="150">
            <template slot-scope="scope">
                {{scope.row.createTimeS * 1000 | formatDate}}
            </template>
        </el-table-column>
        <el-table-column
            prop="status"
            align="center"
            label="状态">
            <template slot-scope="scope">
                <span class="status-up" v-if="scope.row.status === goodsStatus.ON_SALE">已上架</span>
                <span class="status-down" v-else-if="scope.row.status === goodsStatus.SOLD_OUT">已下架</span>
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            fixed="right"
            label="操作" width="150">
            <template slot-scope="scope">
                <el-button type="text" class="table-btn" v-if="scope.row.status === goodsStatus.SOLD_OUT" @click="editGoods(scope.row)">编辑</el-button>
                <el-button type="text" class="table-btn" v-if="scope.row.status === goodsStatus.ON_SALE" @click="upperDownGoods(scope.row)">下架</el-button>
                <el-button type="text" class="table-btn" v-if="scope.row.status === goodsStatus.SOLD_OUT" @click="upperDownGoods(scope.row)">上架</el-button>
                <el-button type="text" class="table-btn" v-if="scope.row.status === goodsStatus.SOLD_OUT" @click="deleteGoods(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30]"
        :page-size="rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
</section>
</template>

<script>
export default {
    data(){
        return {
            tabIndex:0,
            tabList:[
                {
                    name:'全部',
                    num:0,
                    status:'',
                },
                {
                    name:'已上架',
                    num:0,
                    status:1
                },
                {
                    name:'已下架',
                    num:0,
                    status:2
                }
            ],
            list:[],
            currentPage: 1,
            search:'',
            rows: 10,
            total:0,
            status: '',

            // 商品状态
            goodsStatus: {
                ON_SALE: 1, // 上架
                SOLD_OUT: 2 // 下架
            },

            selectionList:[], // 保存批量操作的数据
        }
    },
    created(){
        this.getLists();
    },

    watch: {
    },

    methods: {
        // 搜索商品
        searchGoods() {
            this.currentPage = 1;
            this.getLists();
        },
        resetHandler(){
            this.currentPage = 1;
            this.search = '';
            this.getLists();
        },

        changeTabHandler(i){
            if(this.tabIndex==i) return;
            this.tabIndex = i;
            this.status = this.tabList[i].status;
            this.getLists();
        },

        async getLists(){
            // const loading = this.$loading();
            const rs = await this.$https.cloud({
                action:'goods',
                type:'list',
                formData:{
                    status: this.status,
                    search: this.search,
                    page: this.currentPage,
                    rows: this.rows
                }
            });
            if(rs.code!=0){
                this.$message.error(rs.msg);
                return;
            }

            let arrGoodsList = rs.data.list;
            console.log(arrGoodsList);
            this.list = arrGoodsList;
            const imgList = [];
            arrGoodsList.forEach(goods => {
                imgList.push({ fileid: goods.mainImg });
            });
            let fileList = await this.$https.getImagePath(imgList);
            arrGoodsList.forEach((goods, i) => {
                goods.mainImg = fileList[i].download_url;
            });
            this.list = arrGoodsList;

            let total = rs.data.total.list;
            this.tabList[1].num = 0;
            this.tabList[2].num = 0;
            total.forEach(item=>{
                if(item._id==1){
                    this.tabList[1].num = item.num;
                }else if(item._id==2){
                    this.tabList[2].num = item.num;
                }
            });
            this.tabList[0].num = this.tabList[1].num + this.tabList[2].num;

            if(!this.status){
                this.total = this.tabList[0].num;
            }else{
                this.total = this.tabList[this.status].num;
            }
             
        },

        // 编辑商品
        editGoods(objGoods) {
            console.log(`编辑商品，${objGoods}`);
            this.$router.push({ name: "editGood", query: { id: objGoods._id } })
        },

        // 上架下架商品
        upperDownGoods(objGoods) {
            console.log(`上下架商品，${objGoods}`);
            let self = this;
            let msgContent = objGoods.status === this.goodsStatus.ON_SALE ? "确认下架该商品？" : "确认上架该商品？";
            this.$confirm(msgContent, "提示", {
                type: "warning"
            }).then(() => {
                this.$https.cloud({ 
                    action: "goods",
                    type: "down",
                    formData: {
                        _id: objGoods._id,
                        status: objGoods.status === this.goodsStatus.ON_SALE ? this.goodsStatus.SOLD_OUT : this.goodsStatus.ON_SALE
                    }
                }).then(res => {
                    console.log(res);
                    if (res.code === 0) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                            showClose: true,
                            duration: 1000,
                            onClose() {
                                self.getLists(); // 获取商品列表
                            }
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "warning",
                            showClose: true,
                            duration: 1000
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: objGoods.status === this.goodsStatus.ON_SALE ? "已取消下架该商品！" : "已取消上架该商品！",
                    showClose: ture,
                    duration: 1000
                })
            })
        },

        // 删除商品
        deleteGoods(objGoods) {
            let self = this;
            this.$confirm("确认删除该商品？", "提示", {
                type: "warning"
            }).then(() => {
                this.$https.cloud({ 
                    action: "goods",
                    type: "del",
                    formData: {
                        _id: objGoods._id
                    }
                }).then(res => {
                    console.log(res);
                    if (res.code === 0) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                            showClose: true,
                            duration: 1000,
                            onClose() {
                                self.getLists(); // 获取商品列表
                            }
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "warning",
                            showClose: true,
                            duration: 1000
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除该商品！",
                    showClose: ture,
                    duration: 1000
                })
            })
        },

        //  分页 ========
        // 改变每页显示条数
        handleSizeChange(currentSize) {
            this.rows = currentSize;
            this.getLists(); // 获取商品列表
        },

        // 改变页数
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getLists(); // 获取商品列表
        },

        currentPageHandler(){},
        selectionChangeHandler(val){
            console.log(val);
            this.selectionList = val;
        },
        async changeStatusHandler(status){
            if(this.selectionList.length==0){
                this.$message.error('请选择数据');
                return;
            }
            let ids = [];
            this.selectionList.forEach(item=>{
                if(item.status != status){
                    ids.push(item._id);
                }
            });
            if(ids.length==0){
                this.$message.error('所选的数据不符合要求');
                return;
            }
            const rs = await this.$https.cloud({ 
                    action: "goods",
                    type: "mStatusChange",
                    formData: {
                        ids: ids,
                        status: status
                    }
                });
            if(rs.code==0){
                this.$message.success(rs.msg); 
                this.getLists();
            }else{
                this.$message.error(rs.msg)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';

.goods-manager {
    padding:  0 10px;
    background: #fff;

    .search-wrapper {
        line-height: 79px;
        border-bottom: 2px solid #ccc;
        font-size: 14px;
        .search-input {
            width: 225px;
            display: inline-block;
            margin-right: 25px;
        }
    }

    .goods-title {
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
        padding:  18px 0 13px 0;
    }

    .goods-header {
        line-height: 40px;
        align-items: center;
        background: #fff;
        
        .tab-item {
            user-select: none;
            cursor: pointer;
            display: inline-block;
            margin: 0 2px;
            height:40px;
            line-height: 40px;
            font-size: 14px;
            color: #575757;
            padding: 0 10px;
            border: 1px solid rgba(0,0,0,0.15);
            border-bottom: none;
            border-radius:4px 4px 0px 0px;
            background:rgba(0,0,0,0.02);
        }

        .tab-item_act {
            background: $baseColor;
            color: #fff;
            border-color: $baseColor;
        }

        .add-good {
            display: inline-block;
            width:111px;
            height:30px;
            line-height: 30px;
            border: 1px solid $baseColor;
            border-radius:4px;
            font-size: 14px;
            color: $baseColor;
            text-align: center;
            margin-bottom: 7px;
            margin-left: 10px;
        }
    }

    .table-wrapper {
        border-top: 1px solid #ccc;

        .table-img {
            margin-right: 0.8rem;
            width: 45px;
            height: 45px;
            border: 1px solid #eee;

            .image-slot {
                height: 100%;
            }

            .image-slot [class*="el-icon-"] {
                color: #666;
                font-size: 24px;
                text-align: center;
            }
        }

        .table-name {
            font-size: 14px;
            line-height: 22px;
            flex: 1;
        }

        .table-btn {
            color: $baseColor;
            padding: 0 6px;
            & + .table-btn {
                margin-left: 0;
                border-left: 1px solid #ccc;
            } 
        }

        .status-up {
            color: #52C31C;
        }

        .status-down {
            color: #FF7843;
        }
    }

    .pagination {
        padding:  10px;
        text-align: right;
    }
}
</style>

<style lang="scss">
.goods-manager {
    .table-img {
        /* 修改大图预览组件icon太小的问题 */
        .el-image-viewer__actions__inner [class*="el-icon-"], 
        .el-image-viewer__actions__inner [class^="el-icon-"] {
            font-size: 24px;
        }

        .el-image-viewer__close [class*="el-icon-"], 
        .el-image-viewer__close [class^="el-icon-"] {
            color: #fff;
            font-size: 40px;
        }
        /* 修改大图预览组件icon太小的问题 */
    }
}
</style>